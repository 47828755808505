[data-component="ApplicationFormGuarantors"] {

  .button-wrapper {

    display: flex;
    flex-direction: column;

    > button.circle   {

      > span {
        display: none;
      }

      @media(max-width: 640px) {
        width: unset;
        border-radius: 32px;
        align-self: flex-start;
        height: 2.5em;

        &::before {
          display: none;
        }

        > span {
          display: inline;
        }
      }
    }

    > span {
      border-radius: 30px;
    }

    &::before {
      content: "\00a0";

      @media(max-width: 640px) {
        display: none;
      }
    }
  }

  .guarantor-add-options {
    display: flex;
  }

  .no-items-placeholder {
    font-style: italic;
    color: var(--color-gray-500);
    display: none;

    padding: var(--spacing-100) 0;
  }

  [data-component="GuarantorsList"]:empty {
    + .no-items-placeholder {
      display: flex;
    }
  }

  > h4 {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-primary-500);
    margin-bottom: var(--spacing-100);
  }

  > hr {
    margin: var(--spacing-100) 0 var(--spacing-200) 0;
  }

  > .create-loader {
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
